<script setup>
import { Game } from "@/classes/Portals/Game";
import Fixed from "@/components/Fixed.vue";
import Modal from "@/components/Modal.vue";
import NavigationNew from "@/components/NavigationNew.vue";
import Overlay from "@/components/Overlay.vue";
import store from "@/store";
import { onMounted, computed, onUnmounted, ref, reactive } from "vue";

// const user = computed(() => {
//   return store.state.auth.user;
// });

const game = ref(new Game());

onMounted(async () => {
  // await store.dispatch("auth/getUser");
  // if (user.value) {
  //   console.log(user.value);
  //   await store.dispatch("websockets/connectPrivate", {
  //     channel: `users.${user.value?.my_id}`,
  //     event: "TestUserEvent",
  //     listen: (ev) => {
  //       console.log({ ev });
  //     },
  //   });
  // }

  window.game = game.value;
});

onUnmounted(() => {
  // store.dispatch("websockets/disconnect", {
  //   channel: `users.${user.value?.my_id}`,
  // });
});

const selected = reactive({
  player: null,
  card: null,
});
const playCard = (portal) => {
  game.value.playCard(selected.player, selected.card, portal);
  selectCard(null, null);
  setShowCards(false);
};
const playPass = (player) => {
  game.value.playPass(player);
  setShowCards(false);
};

const selectCard = (player, card) => {
  selected.player = player;
  selected.card = card;
};

const closePortal = (portal) => {
  game.value.closePortal(game.value.currentTurnPlayer, portal);
};

const showCards = ref(false);
const setShowCards = (val) => {
  showCards.value = val;
};
</script>

<template>
  <Fixed v-if="game.winningPlayerId">
    <Overlay>
      <Modal>
        <div>{{ game.getPlayerById(game.winningPlayerId).name }} wins!</div>
      </Modal>
    </Overlay>
  </Fixed>

  <!-- <NavigationNew /> -->
  <div class="relative h-full w-full flex items-center justify-between p-8">
    <!-- Player One -->
    <div class="bg-green-100 p-4 h-full w-1/5" v-if="game.playerOne">
      <div>
        {{ game.playerOne?.name }} {{ game.currentTurnPlayerId === game.playerOne.id ? "*" : "" }}
      </div>
      <div v-if="game.currentTurnPlayerId === game.playerOne.id">
        <button class="btn" @click="setShowCards(!showCards)">
          {{ showCards ? "Hide" : "Show" }} cards
        </button>
      </div>
      <div v-if="game.canPass(game.playerOne)">
        <button class="text-link my-4" @click="playPass(game.playerOne)">Pass turn</button>
      </div>
      <div class="grid grid-cols-2 gap-8">
        <div class="card-list grid grid-cols-1 gap-4">
          <div v-for="(card, c) in game.playerOne.hand" :key="c" class="">
            <div
              :style="{ background: card.color }"
              class="rounded p-4 cursor-pointer"
              @click="selectCard(game.playerOne, card)"
              v-if="showCards && game.currentTurnPlayerId === game.playerOne.id"
            >
              {{ card.value }}
            </div>
            <div v-else class="rounded p-4 bg-gray-400">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Player One -->

    <!-- Center -->
    <div class="grid grid-cols-1 gap-4 flex-grow">
      <div v-for="(portal, p) in game.portals" :key="p" class="">
        <div class="flex items-center justify-center">
          <div class="flex items-center justify-center">
            <div
              v-for="(card, c) in portal.getPlayerCards(game.playerOne.id)"
              :key="c"
              :style="{ background: card.color }"
              class="rounded p-4 cursor-pointer"
            >
              {{ card.value }}
            </div>
          </div>
          <div class="w-36 rounded bg-gray-300">
            <div class="cursor-pointer p-4" @click="playCard(portal)">
              {{ portal.name }}
            </div>

            <div v-if="portal.winningPlayerId">
              winner: {{ game.getPlayerById(portal.winningPlayerId).name }}
            </div>

            <div
              v-if="
                game?.currentTurnPlayer?.canClosePortals?.find((p) => p.id === portal.id)?.canClose
              "
            >
              <button @click="closePortal(portal)" class="btn">Close</button>
            </div>
          </div>
          <div class="flex items-center justify-center">
            <div
              v-for="(card, c) in portal.getPlayerCards(game.playerTwo.id)"
              :key="c"
              :style="{ background: card.color }"
              class="rounded p-4 cursor-pointer"
            >
              {{ card.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Center -->

    <!-- Player Two -->
    <div class="bg-green-100 p-4 w-1/5 h-full" v-if="game.playerTwo">
      <div>
        {{ game.playerTwo?.name }} {{ game.currentTurnPlayerId === game.playerTwo.id ? "*" : "" }}
      </div>
      <div v-if="game.currentTurnPlayerId === game.playerTwo.id">
        <button class="btn" @click="setShowCards(!showCards)">
          {{ showCards ? "Hide" : "Show" }} cards
        </button>
      </div>
      <div v-if="game.canPass(game.playerTwo)">
        <button class="text-link my-4" @click="playPass(game.playerTwo)">Pass turn</button>
      </div>
      <div class="grid grid-cols-2 gap-8">
        <div class="card-list grid grid-cols-1 gap-4">
          <div v-for="(card, c) in game.playerTwo.hand" :key="c" class="">
            <div
              :style="{ background: card.color }"
              class="rounded p-4 cursor-pointer"
              @click="selectCard(game.playerTwo, card)"
              v-if="showCards && game.currentTurnPlayerId === game.playerTwo.id"
            >
              {{ card.value }}
            </div>
            <div v-else class="rounded p-4 bg-gray-400">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Player Two -->
  </div>
  <div class="absolute right-0 bottom-0">
    <div v-if="game.winningPlayerId">Winner: {{ game.winningPlayerId }}</div>
  </div>
  <!-- <div>
      <div>current turn: {{ game.currentTurnPlayer?.name }}</div>
      <div v-if="game.winningPlayerId">Winner: {{ game.winningPlayerId }}</div>
    </div>
    <div class="portals mb-8 grid grid-cols-9 gap-4">
      <div v-for="(portal, p) in game.portals" :key="p">
        <div class="p-4 bg-gray-200 rounded cursor-pointer" @click="playCard(portal)">
          {{ portal.name }}
        </div>
        <div
          v-if="game?.currentTurnPlayer?.canClosePortals?.find((p) => p.id === portal.id)?.canClose"
        >
          <button @click="closePortal(portal)" class="btn">Close</button>
        </div>
        <div>winner: {{ portal.winningPlayerId }}</div>
        <div class="grid grid-cols-2 gap-2">
          <div v-for="(player, p) in game.players" :key="p">
            <div>{{ player?.name }} - {{ portal.getPlayerScore(player.id)?.profile?.name }}</div>
            <div>
              <div
                v-for="(card, c) in portal.getPlayerCards(player.id)"
                :key="c"
                :style="{ background: card.color }"
                class="rounded p-2 text-sm"
              >
                {{ card.value }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-8">
      <div v-for="(player, p) in game.players" :key="p">
        <div>{{ player.name }}</div>
        <div class="card-list grid grid-cols-4 gap-4">
          <div v-for="(card, c) in player.hand" :key="c" class="">
            <div
              :style="{ background: card.color }"
              class="rounded p-4 cursor-pointer"
              @click="selectCard(player, card)"
            >
              {{ card.value }}
            </div>
          </div>
        </div>
      </div>
    </div> -->
</template>
