import { shuffle, random, uniqueId, orderBy } from "lodash-es";

// combine some array of values into sets of all possible combinations of those values at k length
const combinations = (set, k) => {
  var i, j, combs, head, tailcombs;

  // There is no way to take e.g. sets of 5 elements from
  // a set of 4.
  if (k > set.length || k <= 0) {
    return [];
  }

  // K-sized set has only one K-sized subset.
  if (k == set.length) {
    return [set];
  }

  // There is N 1-sized subsets in a N-sized set.
  if (k == 1) {
    combs = [];
    for (i = 0; i < set.length; i++) {
      combs.push([set[i]]);
    }
    return combs;
  }

  combs = [];
  for (i = 0; i < set.length - k + 1; i++) {
    // head is a list that includes only our current element.
    head = set.slice(i, i + 1);
    // We take smaller combinations from the subsequent elements
    tailcombs = combinations(set.slice(i + 1), k - 1);
    // For each (k-1)-combination we join it with the current
    // and store it to the set of k-combinations.
    for (j = 0; j < tailcombs.length; j++) {
      combs.push(head.concat(tailcombs[j]));
    }
  }
  return combs;
};

const cardValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const cardColors = [
  "IndianRed",
  "LightPink",
  "DarkOrange",
  "Gold",
  // "RebeccaPurple",
  "MediumSeaGreen",
  "SkyBlue",
  // "Sienna",
];
const portals = [
  "1986 World Cup",
  "90s Yacht Club",
  "Ancient Rome",
  "Cyberpunk Los Angeles",
  "Feudal Japan",
  "Golden Age of Piracy",
  "Ice Age",
  // "Mars Base 7",
  "Medieval Europe",
  // "Society HQ",
  "Wild West",
];

const initialHandSize = 7;
const winConditionSequence = 3;
const winConditionTotal = 5;

export class Player {
  id;
  name;
  hand = [];

  constructor(data) {
    this.id = data?.id ?? uniqueId("user_");
    this.name = data?.name ?? `Player ${this.id}`;
  }

  addCard(card) {
    card.setPlayer(this);
    this.hand = [...this.hand, card];
  }

  removeCard(card) {
    this.hand = [...this.hand.filter((c) => c.id !== card.id)];

    return card;
  }

  hasCard(card) {
    return this.hand.find((c) => card.id === c.id);
  }
}

export class Game {
  id;
  portals = [];
  players = [];
  startPlayerId = null;
  turns = [];
  deck = [];

  constructor() {
    // setup portals
    this.portals = portals.map((portal, p) => new Portal({ name: portal, order: p }));

    // create deck
    // shuffle deck
    this.deck = shuffle(
      cardValues
        .map((v) => cardColors.map((c) => ({ value: v, color: c })))
        .flat()
        .map((d) => new Card(d))
    );

    // create 2 players
    this.players = [new Player({ name: "Player 1" }), new Player({ name: "Player 2" })];

    // distribute initialHandSize cards to players
    for (let i = 0; i < initialHandSize; i++) {
      this.dealCard(this.players[0]);
      this.dealCard(this.players[1]);
    }

    // randomly select a start player
    this.startPlayerId = this.players[random(0, 1)].id;
  }

  getPlayerById(playerId) {
    return this.players.find((p) => p.id === playerId);
  }

  getPortalById(portalId) {
    return this.portals.find((p) => p.id === portalId);
  }

  playCard(player, card, portal) {
    // check if can play
    // play card
    // draw another card, if possible
    if (this.canPlayCardPortal(player, card, portal)) {
      // ensure acting on the objects of the game, not on copies passed around in UI or other functions
      let objPlayer = this.getPlayerById(player.id);
      objPlayer.removeCard(card);

      let objPortal = this.getPortalById(portal.id);
      objPortal.addCard(card);

      this.dealCard(objPlayer);

      this.addTurn(player.id, card.id, portal.id);
      return true;
    }

    return false;
  }

  playPass(player) {
    this.addTurn(player.id, null, null);
    return true;
  }

  closePortal(player, portal) {
    console.info({ player });

    if (!this.canClosePortal(player, portal)) {
      return;
    }

    // ensure acting on the objects of the game, not on copies passed around in UI or other functions
    let objPortal = this.portals.find((p) => p.id === portal.id);
    objPortal.close();
  }

  dealCard(player) {
    // pull from top of deck, if any left
    if (this.deck[0]) {
      let topCard = this.deck[0];

      this.deck = this.deck.slice(1);

      player.addCard(topCard);
    }
  }

  addTurn(playerId, cardId, portalId) {
    this.turns = [new Turn({ playerId, cardId, portalId }), ...this.turns];
  }

  canPass(player) {
    if (this.currentTurnPlayerId !== player.id) {
      return false;
    }

    return true;
  }

  canPlay(player) {
    for (let c = 0; c < player.hand.length; c++) {
      for (let i = 0; i < this.portals.length; i++) {
        if (this.canPlayCardPortal(player, player.hand[c], this.portals[i])) {
          return true;
        }
      }
    }

    return false;
  }

  canPlayCard(player, card) {
    for (let i = 0; i < this.portals.length; i++) {
      if (this.canPlayCardPortal(player, card, this.portals[i])) {
        return true;
      }
    }

    return false;
  }

  canPlayCardPortal(player, card, portal) {
    // if there is a winner, can't play
    if (this.winningPlayerId) {
      return false;
    }

    // check if player is current turn
    if (this.currentTurnPlayerId !== player.id) {
      return false;
    }

    let playerObj = this.getPlayerById(player.id);

    // check if player has card in hand
    if (!playerObj.hasCard(card)) {
      return false;
    }

    let portalObj = this.getPortalById(portal.id);

    // check the portal says can't add
    // check if portal has 3 cards for player already or other rules
    if (!portalObj.canAdd(card)) {
      return false;
    }

    return true;
  }

  // can prove a portal is won
  canClosePortal(player, portal) {
    // portal must not be won already
    if (portal.winningPlayerId) {
      return false;
    }

    // to prove a portal can won by a player prior to all cards being filled:
    //
    // 1. player must have 3 cards on portal (or equal number of cards)
    //
    // 2. combine other players existing cards with each iteration of cards
    // from both players' hands and the deck
    //
    // if any combination of other player's cards + [any cards left in hands or deck]
    // creates a score greater than this player's score, then return false

    let playerCardsInPortal = portal.getPlayerCards(player.id);

    // can't close if haven't already played all cards
    if (playerCardsInPortal.length < portal.maxCardsPerPlayer) {
      return false;
    }

    // get hand from player
    // get hand from otherplayer
    // get deck
    let otherPlayer = this.players.find((p) => p.id !== player.id);
    let remainingCards = [...player.hand, ...otherPlayer.hand, ...this.deck];
    let otherPlayerCardsInPortal = portal.getPlayerCards(otherPlayer.id);

    let needCardsAmount = portal.maxCardsPerPlayer - otherPlayerCardsInPortal.length;

    // loop through combos and get score of possible set
    let combos = combinations(remainingCards, needCardsAmount);

    let scoreOfPlayerCards = portal.getHandScore(playerCardsInPortal);

    for (let i = 0; i < combos.length; i++) {
      let cardsToCheck = [...otherPlayerCardsInPortal, ...combos[i]];
      let scoreInfo = portal.getHandScore(cardsToCheck);

      if (scoreInfo.score > scoreOfPlayerCards.score) {
        return false;
      }
    }

    return true;
  }

  // return player who won
  get winningPlayerId() {
    // loop through each portal
    // if the winner of any 3 portals in a row, they win
    let sequence = {
      playerId: null,
      number: 0,
    };

    let portal;
    for (let i = 0; i < this.portals.length; i++) {
      portal = this.portals[i];

      if (portal.winningPlayerId) {
        if (sequence.playerId === portal.winningPlayerId) {
          sequence.number = sequence.number + 1;
        } else {
          sequence.playerId = portal.winningPlayerId;
          sequence.number = 1;
        }
      } else {
        sequence.playerId = null;
        sequence.number = 0;
      }

      if (sequence.number >= winConditionSequence) {
        return sequence.playerId;
      }
    }

    // if any player has 5 winning portals, they win
    let playerOneCount = this.portals.filter(
      (p) => p.winningPlayerId === this.players[0].id
    ).length;

    if (playerOneCount >= winConditionTotal) {
      return this.players[0].id;
    }

    let playerTwoCount = this.portals.filter(
      (p) => p.winningPlayerId === this.players[1].id
    ).length;
    if (playerTwoCount >= winConditionTotal) {
      return this.players[1].id;
    }

    return null;
  }

  get currentTurnPlayerId() {
    // if there are no turns, return startplayer
    // otherwise, return the player who didn't have the last turn
    if (this.turns.length < 1) {
      return this.startPlayerId;
    }

    let notLastPlayer = this.players.find((p) => p.id !== this.turns[0].playerId);

    return notLastPlayer.id;
  }

  get playersLoaded() {
    let players = [...this.players]?.map((player) => {
      return {
        ...player,
        canPlay: this.canPlay(player),
        canClosePortals: [
          ...this.portals.map((portal) => {
            return {
              id: portal.id,
              canClose: this.canClosePortal(player, portal),
            };
          }),
        ],
      };
    });

    return players;
  }

  get currentTurnPlayer() {
    return this.playersLoaded.find((p) => p.id === this.currentTurnPlayerId);
  }

  get playerOne() {
    let player = this.playersLoaded[0];
    return {
      ...this.playersLoaded[0],
      isCurrentTurn: player.id === this.currentTurnPlayerId,
    };
  }

  get playerTwo() {
    let player = this.playersLoaded[1];
    return {
      ...this.playersLoaded[1],
      isCurrentTurn: player.id === this.currentTurnPlayerId,
    };
  }
}

export class Turn {
  playerId;
  portalId;
  cardId;

  constructor(data) {
    this.playerId = data.playerId;
    this.portalId = data.portalId;
    this.cardId = data.cardId;
  }
}

export class Card {
  id;
  color;
  value;
  playerId = null;

  constructor(data) {
    this.id = data?.id ?? uniqueId("card_");
    this.color = data.color;
    this.value = data.value;
  }

  setPlayer(player) {
    this.playerId = player.id;
  }
}

export class Portal {
  id;
  name;
  order;
  cards = [];
  isClosed = false;
  maxCardsPerPlayer = 3;

  constructor(data) {
    this.id = data?.id ?? uniqueId("portal_");
    this.name = data?.name ?? `Portal ${this.id}`;
    this.order = data?.order;
  }

  canAdd(card) {
    // can't add if this portal is won
    if (this.winningPlayerId) {
      return false;
    }

    let cardsAlreadyAdded = this.getPlayerCards(card.playerId);
    if (cardsAlreadyAdded?.length >= this.maxCardsPerPlayer) {
      return false;
    }

    return true;
  }

  addCard(card) {
    // can't add if player already added a card
    if (!this.canAdd(card)) {
      return;
    }

    this.cards = [card, ...this.cards];
  }

  getPlayerCards(playerId) {
    return this.cards.filter((c) => c.playerId === playerId);
  }

  close() {
    this.isClosed = true;
  }

  cardsMatchColor(cards = []) {
    if (cards.length < 1) {
      return false;
    }

    let colorsObj = {};

    cards.forEach((c) => {
      colorsObj[c.color] = true;
    });

    let colorsArr = Object.keys(colorsObj);

    return colorsArr.length === 1;
  }

  cardsMatchValue(cards = []) {
    if (cards.length < 1) {
      return false;
    }

    let valueObj = {};

    cards.forEach((c) => {
      valueObj[c.value] = true;
    });

    let valueArr = Object.keys(valueObj);

    return valueArr.length === 1;
  }

  cardsSequential(cards = []) {
    let sortedArray = [...cards];

    sortedArray.sort((a, b) => {
      return a.value - b.value;
    });

    let result = sortedArray.every((f, index) => {
      return index === 0 || f.value - sortedArray[index - 1].value === 1;
    });

    return result;
  }

  cardsTotal(cards = []) {
    return cards?.reduce((prev, curr) => prev + curr.value, 0) ?? 0;
  }

  getHandProfile(cards = []) {
    // • Wedge: Three cards of the same color with consecutive values. [R4][R5][R3], highest is 27
    // • Phalanx: Three cards of the same value. [Y8][R8][G8]; score total of cards, highest is 30
    // • Battalion Order: Three cards of the same color. [B2][B7][B4]; score total of cards, highest is 27
    // • Skirmish Line: Three cards with consecutive values. [Y4][R6][G5]; score total of cards, highest is 27
    // • Host: Any other formation. [Y5][B5][G3] - score total of cards; highest score is 30

    if (cards.length >= this.maxCardsPerPlayer) {
      if (this.cardsSequential(cards) && this.cardsMatchColor(cards)) {
        // return { name: "wedge", add: 100000 };
        return { name: "level5", add: 100000 };
      }

      if (this.cardsMatchValue(cards)) {
        // return { name: "phalanx", add: 10000 };
        return { name: "level4", add: 10000 };
      }

      if (this.cardsMatchColor(cards)) {
        // return { name: "battalion", add: 1000 };
        return { name: "level3", add: 1000 };
      }

      if (this.cardsSequential(cards)) {
        // return { name: "skirmish", add: 100 };
        return { name: "level2", add: 100 };
      }
    }

    // return { name: "host", add: 0 };
    return { name: "level1", add: 0 };
  }

  getHandScore(cards = []) {
    let profile = this.getHandProfile(cards);
    return {
      profile,
      score: this.cardsTotal(cards) + profile.add,
    };
  }

  get winningPlayerId() {
    // if each player has played max cards or isClosed, return the highest score player

    // if this portal is closed, or if both players have reached the max
    if (this.isClosed || this.cards.length >= this.maxCardsPerPlayer * 2) {
      return this.winningPotentialPlayerId;
    }

    return null;
  }

  get winningPotentialPlayerId() {
    // get the scores and order by score hieghest first
    let orderedPlayers = orderBy([...this.playerScores], ["score"], ["desc"]);

    // if the scores are not tied, can return the first (highest) scored player
    if (orderedPlayers?.[0]?.score !== orderedPlayers?.[1]?.score) {
      return orderedPlayers[0].playerId;
    }

    // if the scores are tied get the earliest played final card for each (highest index) and return that player
    let playerOneCards = this.getPlayerCards(orderedPlayers[0].playerId);
    let playerTwoCards = this.getPlayerCards(orderedPlayers[1].playerId);

    let lastPlayerOneCard = playerOneCards?.[0];
    let lastPlayerTwoCard = playerTwoCards?.[0];

    let lastPlayerOneCardIndex = this.cards.indexOf(lastPlayerOneCard);
    let lastPlayerTwoCardIndex = this.cards.indexOf(lastPlayerTwoCard);

    return lastPlayerOneCardIndex > lastPlayerTwoCardIndex
      ? orderedPlayers[0].playerId
      : orderedPlayers[1].playerId;
  }

  get playerScores() {
    let playersObj = {};

    this.cards.forEach((c) => {
      playersObj[c.playerId] = 0;
    });

    let playersArr = Object.keys(playersObj);

    return playersArr.map((pid) => {
      let cards = this.getPlayerCards(pid);
      let score = this.getHandScore(cards);
      return {
        playerId: pid,
        ...score,
      };
    });
  }

  getPlayerScore(playerId) {
    return this.playerScores.find((p) => p.playerId === playerId);
  }
}
